import { useContext, useState } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../components/ui/dialog"
import { Button } from "../../../components/ui/button"
import { Label } from "../../../components/ui/label"
import { Textarea } from "../../../components/ui/textarea"
import { Input } from "../../../components/ui/input"
import { Context } from "../../../context/GlobalState"
import { useParams } from "react-router-dom"
import { arrayToObject, containsKeyValue, hasDuplicateKeyValues } from "."
import { httpRequestO } from "../../../utils/httpsRequest"
import { ScrollArea } from "../../../components/ui/scroll-area"

export default function QueryDocs({  showQueryModal, setShowQueryModal, isDocument=false, documentId=null }) {
    const [documentQuery, setDocumentQuery] = useState("")
    // const [metadata, setQsueries] = useState([{ key: "", value: "" }])

    const [queries, setQueries] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    // const [queryText, setQueryText] = useState('')
    const [results, setResults] = useState({})
    const [resultsNo, setResultsNo] = useState(1)

    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const addMetadata = () => {
        setQueries([...queries, { key: "", value: "" }])
    }

    const removeMetadata = (index) => {
        const updatedMetadata = [...queries]
        updatedMetadata.splice(index, 1)
        setQueries(updatedMetadata)
    }

    const handleMetadataChange = (index, field, value) => {
        const updatedMetadata = [...queries]
        updatedMetadata[index][field] = value
        setQueries(updatedMetadata)
    }

    const handleQuery = () => {

        if (!documentQuery) {
            return addNewNotifcation("Query should not be empty.", 'warning')
        }

        if (hasDuplicateKeyValues(queries, 'key')) {
            return addNewNotifcation("Duplicate key found. Keys should not be duplicated.", 'warning')
        } else if (containsKeyValue(queries, 'key') || containsKeyValue(queries, 'value')) {
            return addNewNotifcation("Empty key or value found. Keys and values should not be empty.", 'warning')
        }


        setBtnLoading(true)
        const queryObj = {
            organization_id: oragID,
            sub_organization_id: subOragID,
            query: documentQuery,
            pre_filter: JSON.stringify(arrayToObject(queries)),
            k: parseInt(resultsNo)
        }
        if (isDocument) {
            queryObj['document_id'] = documentId
        }
        console.log('queryObj: ', queryObj);

        httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, 
            new URLSearchParams(queryObj),
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            }
        ).then((res) => {
            console.log('res: ', res);
            setResults(res.data.data)
        }).catch((err) => {
            console.log('error: ', err);
            addNewNotifcation('Something went wrong. Please try again later', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    return (
        <Dialog open={showQueryModal} onOpenChange={setShowQueryModal}>
            <DialogContent className="max-w-[1000px] w-full max-h-[600px] overflow-scroll m-2">
                <DialogHeader>
                    <DialogTitle>Query Document</DialogTitle>
                    <DialogDescription>Enter the document query and add metadata.</DialogDescription>
                </DialogHeader>
                <div>
                    <Label htmlFor="document-query" className="mb-2">
                        Document Query
                    </Label>
                    <Textarea
                    id="document-query"
                    placeholder="Enter your document query..."
                    value={documentQuery}
                    onChange={(e) => setDocumentQuery(e.target.value)}
                    className="min-h-[100px] max-h-[200px]"
                    />
                </div>
                {!isDocument && ( 
                    <div>
                        <Label className="mb-2">Metadata</Label>
                        <div className="grid gap-2 py-2">
                            {queries.map((item, index) => (
                                <div key={index} className="grid grid-cols-[1fr_1fr_auto] gap-2">
                                    <Input
                                        placeholder="Key"
                                        value={item.key}
                                        onChange={(e) => handleMetadataChange(index, "key", e.target.value)}
                                    />
                                    <Input
                                        placeholder="Value"
                                        value={item.value}
                                        onChange={(e) => handleMetadataChange(index, "value", e.target.value)}
                                    />
                                    <Button type="button" variant="ghost" size="icon" onClick={() => removeMetadata(index)}>
                                        <XIcon className="h-4 w-4" />
                                        <span className="sr-only">Remove metadata</span>
                                    </Button>
                                </div>
                            ))}
                        </div>
                        <Button type="button" className='mt-2' variant="ghost" onClick={addMetadata}>
                            <PlusIcon className="h-4 w-4 mr-2" />
                            Add Metadata
                        </Button>
                    </div>
                )}

                <div className="my-2">
                    <Label htmlFor='result-limit' className="mb-2">Result limits </Label>
                    <Input
                    id='result-limit'
                    max={100}
                    min={1}
                    type='number'
                    value={resultsNo}
                    onChange={(e) => {
                        if (e.target.value <= 100 && e.target.value >= 1) {
                            setResultsNo(e.target.value)
                        } else {
                            addNewNotifcation('Max results number is 100 and min results is 1', 'warning')
                        }
                    }}/>
                </div>
                <div className="mt-4">
                    <Label className="mb-4">Result </Label>
                    <ScrollArea className="flex flex-col gap-3 max-h-[400px] overflow-y-auto rounded-md p-4 border">
                        {results?.documents?.map((item, i) => (
                            <div key={i} className='flex w-full'>
                                {/* <Textarea disabled className='min-h-[150px] max-h-[300px] resize-none m-2' name="query" value={item.page_content} /> */}
                                <span className="text-sm border p-2 m-2">{item.page_content}</span>
                            </div>
                        ))}
                    </ScrollArea>
                </div>

                <DialogFooter>
                    <Button disabled={btnLoading} onClick={handleQuery} type="button">Query</Button>
                </DialogFooter>

            </DialogContent>
        </Dialog>
    )
}

function PlusIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
            <path d="M5 12h14" />
            <path d="M12 5v14" />
        </svg>
    )
}


function XIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
        </svg>
    )
}